<template>
    <div>
        <main-nav fixed></main-nav>
        <l-right-contact></l-right-contact>

        <l-banner
                title="AI服饰检测"
                summary="YOCHUR自主研发的服饰数据识别算法优化技术，精准实现“人衣对接”、“整套LOOK方案”高匹配
                做到真正地用AI人工智能量化时尚，洞悉科技大变革背景下的行业发展趋势，获得未来先机。"
                background-image="https://static.ycsd.work//site/apps/banner.png"
        ></l-banner>

        <div class="modules">

            <title-green
                    title="功能介绍"
                    color="rgba(51, 51, 51, 1)"
                    text-background="https://static.ycsd.work/web/ai/t1.png"
            ></title-green>

            <div class="modules-content">
                <div class="m1">

                    <div class="left">
                        <img class="m1-img" src="@/assets/images/ai/01.png" alt="">
                        <div class="m1-text">
                            分析图片中的指定服饰，运用计算机视觉技术分析出服饰的各类属性信息，对服装配饰等商品的风格属性进行理解
                        </div>
                        <div class="m1-line"></div>
                    </div>
                    <div class="right">
                        <img class="m1-img-2" src="@/assets/images/ai/02.png" alt="">
                    </div>

                </div>


                <div class="m2">

                    <div class="left">
                        <img class="m2-img" src="@/assets/images/ai/03.png" alt="">
                    </div>

                    <div class="right">
                        <img class="m2-img-1" src="@/assets/images/ai/04.png" alt="">
                        <div class="m2-text">
                            输入一键服饰单品后，AI模型自动推荐多件多套适合该单品的搭配，核心算法模块为服饰商品图之间的“视觉相搭性”特征算法模型，能够衡量两件及多件服饰之间的搭配关系
                        </div>
                        <div class="m2-line"></div>
                    </div>
                </div>


                <div class="m3">
                    <div class="left">
                        <img class="m3-img" src="@/assets/images/ai/05.png" alt="">
                        <div class="m3-text">
                            核心算法模块为“搭配适宜风格”分类模型，能判断一套服饰搭配更适宜于哪种风格场景，输入一套服饰搭配，AI将判断该套服饰在“约会”、“逛街”、“居家”、“运动”等十个场景中，推荐该套搭配最适用的1-3个场景
                            并给予每个适宜场景适宜度评分。
                        </div>
                        <div class="m3-line"></div>
                    </div>
                    <div class="right">
                        <img class="m3-img-1" src="@/assets/images/ai/06.png" alt="">
                    </div>
                </div>

            </div>
        </div>

        <scen-app></scen-app>

        <patent></patent>

        <l-footer></l-footer>

        <l-icp></l-icp>
    </div>
</template>

<script>
    import MainNav from "../../components/MainNav";
    import TitleGreen from "../../components/TitleGreen";
    import ScenApp from "../../components/ScenApp";
    import Patent from "../../components/Patent";
    import LFooter from "../../components/LFooter";
    import LIcp from "../../components/LIcp";
    import LBanner from "../../components/LBanner";
    import LRightContact from "../../components/LRightContact";
    import meta from "../../meta";

    export default {
        name: "ClothingDetection",
        components: {LRightContact, LBanner, LIcp, LFooter, Patent, ScenApp, TitleGreen, MainNav},
        metaInfo: meta.appendTitle('AI服饰检测')
    }
</script>

<style lang="less" scoped>


    .modules {
        padding: 176px 350px 0 350px;

        .left, .right {
            width: 50%;
            float: left;
            position: relative;
        }
    }

    .modules-content {
        text-align: left;
    }

    .m1 {
        margin-bottom: 200px;
        overflow: hidden;

        .left {
            padding-top: 115px;
        }
    }

    .m1-img {
        width: 268px;
        margin-bottom: 40px;
    }

    .m1-text {
        width: 486px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        padding-bottom: 60px;
    }

    .m1-line {
        width: 669px;
        height: 2px;
        background-color: #333333;
        position: absolute;
    }

    .m1-img-2 {
        width: 591px;
    }

    .m2 {
        overflow: hidden;
        margin-bottom: 200px;

        .left {
            text-align: right;
        }

        .right {
            padding-top: 131px;
            padding-left: 123px;
        }
    }

    .m2-img {
        width: 590px;
    }

    .m2-img-1 {
        width: 268px;
    }

    .m2-text {
        width: 489px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        margin-bottom: 60px;
    }

    .m2-line {
        width: 652px;
        height: 2px;
        background-color: #333333;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1
    }

    .m3 {
        overflow: hidden;
        margin-bottom: 176px;

        .left {
            padding-top: 145px;
        }
    }

    .m3-img {
        width: 273px;
        margin-bottom: 40px;
    }

    .m3-text {
        width: 486px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        margin-bottom: 60px;
    }

    .m3-line {
        height: 2px;
        background-color: #333333;
        width: 100%;
    }

    .m3-img-1 {
        width: 595px;
    }

</style>